import * as React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>Entraste en una ruta que no existe...</p>
    <a href="/">Ir a inicio</a>
  </>
)

export default NotFoundPage
